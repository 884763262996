@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");

body {
  font-family: "Manrope" !important;
  color: $jbre-navy-dark !important;
}

.contact-header-component {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: $jbre-navy-dark;
  padding: 0 18px;
  color: $inactive-white;

  a {
    text-decoration: none;
    color: $inactive-white;
  }

  p {
    font-size: 14px;
    margin: 5px 20px 5px 0px;
    color: $inactive-white;
    font-weight: 600;
  }

  .contact-header-icon {
    font-size: 18px;
    margin: 7px 5px 0px 0px;
  }

  & > div {
    display: flex;
  }
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $inactive-white;
  border-radius: 10px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;

  &.middle {
    margin-top: 0px;
  }
}

.navbar-component {
  .navbar-toggler {
    margin-right: 7px;
    border: none !important;
    width: 20px;
    height: 20px;
    position: relative;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }

    .toggler-icon {
      &.top {
        margin-top: 0px;
        transform: rotate(135deg);
      }

      &.middle {
        transform: translateX(10px);
        opacity: 0;
        filter: alpha(opacity=0);
      }

      &.bottom {
        margin-top: 0px;
        transform: rotate(-135deg);
      }
    }

    &.collapsed {
      .toggler-icon {
        &.top {
          margin-top: -10px;
          transform: rotate(0deg);
        }

        &.middle {
          opacity: 1;
          filter: alpha(opacity=100);
          transform: translateX(0px);
        }

        &.bottom {
          margin-top: 10px;
          transform: rotate(0deg);
        }
      }
    }
  }

  .navbar {
    background-color: $jbre-navy-dark !important;
    border: 1px solid $jbre-navy-dark;

    #navbarNav {
      margin-right: 7px;
    }

    .navbar-nav {
      float: right;
      text-align: right;
    }
  }

  .navbar-brand {
    color: $pure-white;

    &:hover {
      color: $pure-white;
    }

    .navbar-logo {
      width: 120px;
    }
  }

  .nav-link {
    color: $inactive-white;

    &:hover {
      color: $pure-white !important;
    }

    &::after {
      content: ""; /* This is necessary for the pseudo element to work. */
      display: block; /* This will put the pseudo element on its own line. */
      margin: 0 0; /* This will add margins to the border. */
      width: 70%; /* Change this to whatever width you want. */
      padding-top: 5px; /* This creates some space between the element and the border. */
      border-bottom: 1px solid $pure-white; /* This creates the border. Replace black with whatever color you want. */
    }

    &.active {
      color: $inactive-white !important;

      &:hover {
        color: $pure-white !important;
      }
    }
  }
}

.home-page {
  .banner-component {
    img {
      width: 100%;
      object-fit: cover;
      max-height: 88vh;
    }
  }

  .services-component {
    padding: 50px;

    img {
      width: 100%;
    }

    h2 {
      text-align: center;
    }

    h3 {
      text-align: center;
    }
  }
}

.featured-video-component {
  padding: 50px;
  text-align: center;
  background-color: $background-light-blue;
}

.find-me-component {
  padding: 50px;
  text-align: center;

  .social-icon-links-container {
    margin-top: 30px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;

    a {
      text-decoration: none;
      color: $jbre-navy-dark;

      &:hover {
        color: $jbre-navy-light;
        transition: 0.3s;
      }
    }

    .social-icon-link {
      margin: 25px;
      font-size: 50px;
    }
  }
}

.instagram-embed {
  iframe {
    margin: 35px 0px 50px 0px;
  }
}

.youtube-embed {
  iframe {
    width: 60vw;
    height: 500px;
    margin-top: 25px;
  }
}

.about-page {
  padding: 50px;

  .about-component {
    h2 {
      text-align: center;
      margin-bottom: 50px;
    }

    .about-image {
      img {
        width: 100%;
        padding: 0 0 30px 0;
      }
    }

    .about-description {
      margin: auto;
    }
  }
}

.book-appointment-page {
  padding: 50px;

  h2 {
    text-align: center;
    margin-bottom: 40px;
  }

  .calendly-inline-widget {
    overflow: hidden;
    min-width: 320px;
    height: 660px;
  }
}

.social-media-page {
  padding: 50px;

  h2 {
    text-align: center;
    margin-bottom: 50px;
  }

  .embedded-feeds {
    text-align: center;

    .tik-tok-embed {
      text-align: center;
      margin: 0px auto 50px auto;
      justify-content: center;
      display: flex;
    }

    .side-by-side-embed {
      margin-bottom: 40px;
    }
  }
}

.contact-page {
  padding: 50px;

  h2 {
    text-align: center;
    margin-bottom: 30px;
  }

  .contact-section {
    margin: auto;

    .form-result-actions {
      text-align: center;
    }
  }

  .contact-form {
    margin: 0 auto 40px auto;
    width: 50vw;

    form {
      width: 100%;
    }

    input[type="text"],
    input[type="email"] {
      width: 100%;
      height: 35px;
      margin: 5px 0 20px 0;
      font-size: 15px;
      border: 1px solid $pure-black;
      border-radius: 2px;
    }

    textarea {
      width: 100%;
      height: 150px;
      margin: 5px 0 20px 0;
      font-size: 15px;
      border: 1px solid $pure-black;
      border-radius: 2px;
    }

    select {
      width: 100%;
      height: 35px;
      margin: 5px 0 20px 0;
      font-size: 15px;
      background-color: $pure-white;
      border: 1px solid $pure-black;
      border-radius: 2px;
      color: $jbre-navy-dark;
    }

    option {
      color: $jbre-navy-dark;
    }

    input[type="checkbox"] {
      margin-right: 10px;
      font-size: 15px;

      &:hover {
        cursor: pointer;
      }
    }

    label {
      display: inline;
    }

    input[type="submit"] {
      width: 100px;
      height: 35px;
      margin: 20px 0 20px 0;
      font-size: 15px;
      float: right;
      border: none;
      background-color: $jbre-navy-dark;
      color: $pure-white;
      border-radius: 4px;
    }
  }

  .contact-image {
    margin: auto;
    text-align: center;

    img {
      border-radius: 100%;
      width: 25vw;
    }
  }
}

.privacy-policy-page {
  padding: 50px;

  .privacy-policy-component {
    h2 {
      text-align: center;
      margin-bottom: 50px;
    }
  }
}

.footer-component {
  padding: 50px;
  background-color: $jbre-navy-dark;

  p {
    color: $inactive-white;
    font-size: 11px;
  }

  a {
    color: $inactive-white;
    font-size: 11px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .social-icon-links-container {
    justify-content: flex-end;
    display: flex;
    color: $inactive-white;

    a {
      text-decoration: none;
      color: $inactive-white;
    }

    .social-icon-link {
      margin: 8px;
      font-size: 23px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .navbar-component {
    .nav-link {
      &::after {
        content: "";
        display: block;
        margin: 0px 0px 0px auto;
        padding-top: 5px;
        border-bottom: 1px solid #ffffff;
        width: 35%;
      }
    }
  }

  .find-me-component {
    h4 {
      margin: 0;
    }

    .social-icon-links-container {
      .social-icon-link {
        font-size: 30px;
      }
    }
  }

  .contact-page {
    padding: 50px;

    .contact-form {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .contact-page {
    .contact-image {
      img {
        width: 40vw;
      }
    }
  }
}
